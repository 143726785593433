import React, {useEffect, useRef, useState} from "react";
import {Button, Modal, Table, Tooltip, Tabs} from "antd";
import {DeleteOutlined, EditOutlined, BorderOutlined, RightOutlined, CloseOutlined, CarOutlined } from '@ant-design/icons';
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useInterval from "react-useinterval";
import fusion from "../services/fusion.service";
import tracker from "../services/tracker.service";
import {downloadTableAsCsv} from "../services/export-csv.service";
import {engine_broadcast} from "../classes/engine_broadcast";
import _ from "lodash";
const {TabPane} = Tabs;
const {confirm} = Modal;

const fusionListTab = "1"
const trackersStateTab = "2"
const trackerSupervisionTab = "3"
const GET_TRACKERS_STATE_LIST_FREQUENCY = 3000;
export default function TrackerFusionList() {
    const {t} = useTranslation();
    // eslint-disable-next-line
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState("1");
    const [trackersStatePageDisplayed, setTrackersStatePageDisplayed] = useState(false)
    const [availableFusions, setAvailableFusions] = useState([]);
    const [trackerStates, setTrackerStates] = useState([]);
    const [trackersSupervision, setTrackersSupervision] = useState([]);
    const history = useHistory();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const webSocket = useRef();

    const showModal = () => {
        setIsModalVisible(true);
    };

    const changeTab = (activeKey) => {
        setActiveTab(activeKey);
        switch (activeKey) {
            case fusionListTab:
                closeSocket();
                getFusionList();
                setTrackersStatePageDisplayed(false);
                break;
            case trackersStateTab:
                closeSocket();
                setTrackersStatePageDisplayed(true);
                break;
            case trackerSupervisionTab:
                connectSocket();
                setTrackersStatePageDisplayed(false);
                break;
            default:
                // do nothing
        }
    };

    const getTrackersStateList = () => {
        tracker.getTrackersState()
            .then((r) => {
                if (r && r.data) {
                    setTrackerStates(r.data);
                    setLoading(false);
                }
            });
    }

    const getFusionList = () => {
        fusion.list()
            .then((r) => {
                if (r && r.data) {
                    setAvailableFusions(r.data.map(fusion => {
                        return {
                            id: fusion.id,
                            fusionId: fusion.fusionId,
                            tracker1: fusion.trackers[0] ? fusion.trackers[0] : '-',
                            tracker2: fusion.trackers[1] ? fusion.trackers[1] : '-',
                            tracker3: fusion.trackers[2] ? fusion.trackers[2] : '-',
                            tracker4: fusion.trackers[3] ? fusion.trackers[3] : '-',
                            tracker5: fusion.trackers[4] ? fusion.trackers[4] : '-',
                        }
                    }));
                    setLoading(false);
                }
            });
    }

    const deleteFusion = (id) => {
        fusion.del(id)
            .then((r) => {
                if (r.status === 204) {
                    getFusionList();
                }
            });
    }

    // handler --------------------------------------------------------
    const create = () => {
        history.push('/fusion/');
    }

    const exportCsv = () => {
        downloadTableAsCsv('merge-trackers-list', [0,1,2,3,4,5]);
    }

    const importCsv = () => {
        history.push('/fusion-import/');
    }

    const edit = (e, id) => {
        history.push('/fusion/' + id);
    }

    const setTrackerLive = (id) => {
        tracker.setTrackerState({id: id, state:"live"}).then();
    }

    const setTrackerStandby = (id) => {
        tracker.setTrackerState({id: id, state:"standby"}).then();
    }

    const setTrackerShip = (id) => {
        tracker.setTrackerState({id: id, state:"ship"}).then();
    }

    const setTrackerCancelShip = (id) => {
        tracker.setTrackerState({id: id, state:"cancel_ship"}).then();
    }

    const setAllTrackersLive = () => {
        tracker.setAllTrackersState({state:"live"}).then();
    }

    const setAllTrackersStandby = () => {
        tracker.setAllTrackersState({state:"standby"}).then();
    }

    const setAllTrackersShip = () => {
        setIsModalVisible(false);
        tracker.setAllTrackersState({state:"ship"}).then();
    }

    const setAllTrackersCancelShip = () => {
        tracker.setAllTrackersState({state:"cancel_ship"}).then();
    }

    const setLive = (fusionId) => {
        fusion.setFusionState({fusionId: fusionId, state:true}).then();
    }

    const setStandby = (fusionId) => {
        fusion.setFusionState({fusionId: fusionId, state:false}).then();
    }

    const del = async (e, id) => {
        confirm({
            title: 'Confirmer la suppression ?',
            content: 'La suppression est définitive',
            onOk() {
                return new Promise(async (resolve) => {
                    deleteFusion(id);
                    resolve();
                });
            },
            onCancel() {
            },
        });
    }

    // engine broadcast reciever --------------------------------------
    const wsReceive = (self, res) => {
        let cmd = res[0];
        let data = res[1];
        if (_.isEmpty(self)) {
            return;
        }
        switch (cmd) {
            case "all_trackers_supervision":
                setTrackersSupervision(data.sort((a, b) => {
                    if (a.req_state === 'ON' && b.req_state !== 'ON') {
                        return -1;
                    } else if (a.req_state !== 'ON' && b.req_state === 'ON') {
                        return 1;
                    } else {
                        return a.id - b.id;
                    }
                }));
                break;
            // ========= DEFAULT =========
            default:
                break;
        }
    }

    const connectSocket = () => {
        webSocket.current = new engine_broadcast();
        webSocket.current.set_callback(wsReceive);
        webSocket.current.connect();
        webSocket.current.send_cmd('all_trackers_supervision', 'start');
        window.addEventListener('beforeunload', closeSocket);
    }

    const closeSocket = () => {
        if (webSocket.current) {
            webSocket.current.send_cmd('all_trackers_supervision', 'stop');
            webSocket.current.close();
            window.removeEventListener('beforeunload', closeSocket);
        }
    }

    useInterval( () => {
        if (trackersStatePageDisplayed) {
            getTrackersStateList();
        }
    },GET_TRACKERS_STATE_LIST_FREQUENCY);

    // useEffect ------------------------------------------------------
    useEffect(() => {
        setLoading(true);
        getFusionList();
        return () => {
            closeSocket();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // column definition ----------------------------------------------
    const columnsTrackersSupervision = [
        {
            title: t('TrackerSupervision.id'),
            dataIndex: 'id',
            width: '5%',
            sorter: (a, b) => a.id - b.id
        },
        {
            title: t('TrackerSupervision.chrg'),
            dataIndex: 'isInCharge',
            width: '5%',
            sorter: (a, b) => a.isInCharge.localeCompare(b.isInCharge)
        },
        {
            title: t('TrackerSupervision.ds'),
            dataIndex: 'debit_send',
            width: '5%'
        },
        {
            title: t('TrackerSupervision.dr'),
            dataIndex: 'debit_receive',
            width: '5%'
        },
        {
            title: t('TrackerSupervision.lt'),
            dataIndex: 'last_time',
            width: '5%'
        },
        {
            title: t('TrackerSupervision.gps'),
            dataIndex: 'nb_gps',
            width: '5%'
        },
        {
            title: t('TrackerSupervision.dw'),
            dataIndex: 'nb_dw',
            width: '5%'
        },
        {
            title: t('TrackerSupervision.ts_dw'),
            dataIndex: 'nb_ts_dw',
            width: '5%'
        },
        {
            title: t('TrackerSupervision.sat'),
            dataIndex: 'num_sv',
            width: '5%'
        },
        {
            title: t('TrackerSupervision.mod'),
            dataIndex: 'mode',
            width: '5%',
            sorter: (a, b) => a.mode.localeCompare(b.mode)
        },
        {
            title: t('TrackerSupervision.bat'),
            dataIndex: 'bat',
            width: '5%'
        },
        {
            title: t('TrackerSupervision.csq'),
            dataIndex: 'qual_3g',
            width: '5%'
        },
        {
            title: t('TrackerSupervision.ntk'),
            dataIndex: 'mccMnc',
            width: '5%',
            sorter: (a, b) => a.mccMnc.localeCompare(b.mccMnc)
        },
        {
            title: t('TrackerSupervision.type'),
            dataIndex: 'ntwkTechnology',
            width: '5%'
        },
        {
            title: t('TrackerSupervision.percent_dw'),
            dataIndex: 'invalid_dw_ratio_1',
            width: '5%'
        },
        {
            title: t('TrackerSupervision.delta_dw'),
            dataIndex: 'invalid_dw_maxDelayMean_1',
            width: '5%'
        },
        {
            title: t('TrackerSupervision.hr'),
            dataIndex: 'hr_value',
            width: '5%'
        },
        {
            title: t('TrackerSupervision.pwr'),
            dataIndex: 'power_value',
            width: '5%'
        },
    ]

    const columnsTrackersState = [
        {
            title: t('TrackerFusion.fusionId'),
            dataIndex: 'id',
            hidden: true
        },
        {
            title: t('TrackerState.number'),
            dataIndex: 'num_capt',
            sorter: (a, b) => a.num_capt - b.num_capt,
        },
        {
            title: t('TrackerState.fw'),
            dataIndex: 'version_fw'
        },
        {
            title: t('TrackerState.iccid'),
            dataIndex: 'iccid'
        },
        {
            title: t('TrackerState.type'),
            dataIndex: 'type'
        },
        {
            title: t('TrackerState.lastMessage'),
            dataIndex: 'last_message_reception_time',
            sorter: (a, b) => new Date(a.last_message_reception_time) - new Date(b.last_message_reception_time)
        },
        {
            title: t('TrackerState.state'),
            dataIndex: 'state',
            render: (value) => (value) ? 'ON' : 'OFF',
            sorter: (a, b) => a.state - b.state
        },
        {
            title: t('TrackerState.stateNeed'),
            dataIndex: 'state_need',
            render: (value) => (value) ? 'ON' : 'OFF',
            sorter: (a, b) => a.state_need - b.state_need
        },
        {
            title: t('TrackerFusion.action'),
            dataIndex: 'action',
            key: 'action',
            className: 'col_action',
            render: (value, record) => {
                return <>
                    <span className="action">
                        <Tooltip title={`${t('TrackerState.live')}${record.num_capt}`}>
                            <Button onClick={() => setTrackerLive(record.id)} shape="circle" icon={<RightOutlined/>}/>
                        </Tooltip>
                    </span>
                    <span className="action">
                        <Tooltip title={`${t('TrackerState.standby')}${record.num_capt}`}>
                            <Button onClick={() => setTrackerStandby(record.id)} shape="circle" icon={<BorderOutlined/>}/>
                        </Tooltip>
                    </span>
                    <span className="action">
                        <Tooltip title={`${t('TrackerState.ship')}${record.num_capt}`}>
                            <Button onClick={() => setTrackerShip(record.id)} shape="circle" icon={<CarOutlined/>}/>
                        </Tooltip>
                    </span>
                    <span className="action">
                        <Tooltip title={`${t('TrackerState.cancelShip')}${record.num_capt}`}>
                            <Button onClick={() => setTrackerCancelShip(record.id)} shape="circle" icon={<CloseOutlined/>}/>
                        </Tooltip>
                    </span>
                </>
            }
        },
    ].filter(item => !item.hidden);

    const columns = [
        {
            title: t('TrackerFusion.fusionId'),
            dataIndex: 'fusionId',
            sorter: (a, b) => a.fusionId - b.fusionId,
        },
        {
            title: t('TrackerFusion.trackerId1'),
            dataIndex: 'tracker1'
        },
        {
            title: t('TrackerFusion.trackerId2'),
            dataIndex: 'tracker2'
        },
        {
            title: t('TrackerFusion.trackerId3'),
            dataIndex: 'tracker3'
        },
        {
            title: t('TrackerFusion.trackerId4'),
            dataIndex: 'tracker4'
        },
        {
            title: t('TrackerFusion.trackerId5'),
            dataIndex: 'tracker5'
        },
        {
            title: t('TrackerFusion.action'),
            dataIndex: 'action',
            key: 'action',
            className: 'col_action',
            render: (value, record) => {
                return <>
                    <span className="action">
                        <Tooltip title={`${t('utilisateur.live')}${record.fusionId}`}>
                            <Button onClick={() => setLive(record.fusionId)} shape="circle" icon={<RightOutlined/>}/>
                        </Tooltip>
                    </span>
                    <span className="action">
                        <Tooltip title={`${t('utilisateur.standby')}${record.fusionId}`}>
                            <Button onClick={() => setStandby(record.fusionId)} shape="circle" icon={<BorderOutlined/>}/>
                        </Tooltip>
                    </span>
                    <span className="action">
                        <Tooltip title={t('utilisateur.Modifier')}>
                            <Button onClick={(e) => edit(e, record.fusionId)} shape="circle" icon={<EditOutlined/>}/>
                        </Tooltip>
                    </span>
                    <span className="action">
                        <Tooltip title={t('utilisateur.supprimer')}>
                            <Button onClick={(e) => del(e, record.id)} shape="circle" icon={<DeleteOutlined/>}/>
                        </Tooltip>
                    </span>
                </>
            }
        },
    ]

    // render ---------------------------------------------------------
    return (
        <div className="private_page_wrapper">
            <Modal
                title={`${t('TrackerState.allShip')}`}
                visible={isModalVisible}
                onOk={setAllTrackersShip}
                onCancel={() => setIsModalVisible(false)}>
                <span> {t('TrackerState.setShipModeMsgConfirm')}</span>
            </Modal>
            <div className="merge_page_wrapper">
                <Tabs defaultActiveKey="1" activeKey={activeTab} onChange={changeTab}>
                    <TabPane tab={t('TrackerFusion.title')} key="1">
                        <div className="header" id="tracker_fusion">
                            <div className="sub_header_title">
                                <span className="subtitle">{t('TrackerFusion.subtitle')}</span>
                            </div>
                            <div className="sub_header_action">
                                <Button className="btn_add" onClick={create}>{t('common.add')}</Button>
                            </div>
                            <div className="sub_header_action">
                                <Button className="btn_export" onClick={importCsv}>{t('common.import')}</Button>
                            </div>
                            <div className="sub_header_action">
                                <Button className="btn_export" onClick={exportCsv}>{t('common.export')}</Button>
                            </div>
                        </div>

                        <div className="data_list">
                            <Table
                                id="merge-trackers-list"
                                columns={columns}
                                dataSource={availableFusions}
                                rowKey="fusionId"
                                loading={loading}
                                className="common_list tracker_fusion_list"
                                pagination={false}
                            />
                        </div>
                    </TabPane>
                    <TabPane tab={t('TrackerState.title')} key="2">
                        <div className="header" id="tracker_state">
                            <div className="sub_header_title">
                                <span className="subtitle">{t('TrackerState.subtitle')}</span>
                            </div>
                            <div className="sub_header_action">
                                <Button className="btn_add" onClick={setAllTrackersLive} icon={<RightOutlined/>}>{t('TrackerState.allLive')}</Button>
                            </div>
                            <div className="sub_header_action">
                                <Button className="btn_export" onClick={setAllTrackersStandby} icon={<BorderOutlined/>}>{t('TrackerState.allStandby')}</Button>
                            </div>
                            <div className="sub_header_action">
                                <Button className="btn_export" onClick={showModal} icon={<CarOutlined/>}>{t('TrackerState.allShip')}</Button>
                            </div>
                            <div className="sub_header_action">
                                <Button className="btn_export" onClick={setAllTrackersCancelShip} icon={<CloseOutlined/>}>{t('TrackerState.allCancelShip')}</Button>
                            </div>
                        </div>

                        <div className="data_list">
                            <Table
                                id="trackers-status-list"
                                columns={columnsTrackersState}
                                dataSource={trackerStates}
                                rowKey="num_capt"
                                loading={loading}
                                className="common_list tracker_fusion_list"
                                rowClassName={(record) => {
                                    if (record.state) {
                                        return 'live';
                                    } else if (record.state_need) {
                                        return 'live_requested';
                                    }
                                    return '';
                                }}
                                pagination={false}
                            />
                        </div>
                    </TabPane>
                    <TabPane tab={t('TrackerSupervision.title')} key="3">
                        <div className="header" id="tracker_supervision">
                            <div className="sub_header_title">
                                <span className="subtitle">{t('TrackerSupervision.subtitle')}</span>
                            </div>
                        </div>

                        <div className="data_list">
                            <Table
                                id="trackers-supervision-list"
                                columns={columnsTrackersSupervision}
                                dataSource={trackersSupervision}
                                rowKey="id"
                                loading={loading}
                                className="common_list tracker_fusion_list"
                                rowClassName={(record) => {
                                    if (record.req_state === 'ON') {
                                        return 'live';
                                    }
                                    if (record.isInCharge === 'ON') {
                                        return 'in_charge';
                                    }
                                    return '';
                                }}
                                pagination={false}
                            />
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )

}