import React, {useEffect, useMemo, useState} from "react";
import {GoogleMap, InfoWindow, LoadScript, Marker, Polyline} from "@react-google-maps/api";

// COMPONENTS
import Loader from "../components/loader"
import {useCurrentRaceTrackContext, usePositionRaceDataContext} from "../pages/privateMonitoring";
import {Button, Drawer, Switch} from "antd";
import {useTranslation} from "react-i18next";
import {CheckOutlined, CloseOutlined, UpOutlined} from "@ant-design/icons";

// MAP containerStyle 
const containerStyle = {
    position: "relative",
    width: "100%",
    height: "100%"
};

const default_map_options = {
    streetViewControl: false,
    scaleControl: true,
    fullscreenControl: true,
    fullscreenControlOptions: {
        position: 6.0 // value for LEFT_BOTTOM
    },
    gestureHandling: "greedy",
    disableDoubleClickZoom: true,
    minZoom: 3,
    maxZoom: 20,
    mapTypeControl: false,
    zoomControl: true,
    clickableIcons: false,
    disableDefaultUI: true
}

const icon_size = 10;

export const MonitoringMap = React.forwardRef((props, ref) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(true);
    const [map, setMap] = useState();
    const raceTrack = useCurrentRaceTrackContext();
    const positionRaceData = usePositionRaceDataContext();
    const [mapCenter, setMapCenter] = useState();
    const [mapZoom, setMapZoom] = useState(17);
    const [mapOptions, setMapOptions] = useState(false);
    const [innerTrackMarkerList, setInnerTrackMarkerList] = useState([]);
    const [outerTrackMarkerList, setOuterTrackMarkerList] = useState([]);
    const [pipelineList, setPipelineList] = useState([]);
    const [computedPositionMarkerList, setComputedPositionMarkerList] = useState([]);
    const [rawPositionMarkerList, setRawPositionMarkerList] = useState([]);
    const [showInnerTrack, setShowInnerTrack] = useState(true);
    const [showTrackGate, setShowTrackGate] = useState(true);
    const [showOuterTrack, setShowOuterTrack] = useState(true);
    const [showRawPosition, setShowRawPosition] = useState(false);
    const [showComputedPosition, setShowComputedPosition] = useState(true);
    const [followRace, setFollowRace] = useState([]);
    const [mapType, setMapType] = useState('SATELLITE');
    const [fitBounds, setFitBounds] = useState([]);
    const [visible, setVisible] = useState(false);
    const [toolbarButton, setToolbarButton] = useState();

    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };

    // onMapLoad ------------------------------------------------------
    const onMapLoad = (map) => {
        setMap(map);
        if (mapType === 'ROADMAP') {
            map.setMapTypeId(window.google.maps.MapTypeId.ROADMAP);
        } else {
            map.setMapTypeId(window.google.maps.MapTypeId.SATELLITE);
        }
    };

    const clearMap = () => {
        if (!window.google || !map) {
            return;
        }
        setComputedPositionMarkerList([]);
        setInnerTrackMarkerList([]);
        setOuterTrackMarkerList([]);
        setPipelineList([]);
        for (let i = 0; i < rawPositionMarkerList.length; i++) {
            rawPositionMarkerList[i].marker.setMap(null);
        }
        rawPositionMarkerList.splice(0, rawPositionMarkerList.length);
        setRawPositionMarkerList([]);
    }

    const getToolbarButton = () => {
        return <Button type="primary" className="map_markers_drawer_btn" onClick={showDrawer}><UpOutlined /></Button>;
    }

    const cleanMarkers = (markers) => {
        markers.forEach(function(marker){
            marker.marker.setMap(null);
        });
    }

    const setComputedPositionData = (computedRacePositionData) => {
        if (!window.google || !map) {
            return;
        }
        // undraw the race data when marker list is set but empty computed race data are then sent
        if (computedRacePositionData && computedRacePositionData.length === 0 && computedPositionMarkerList.length > 0) {
            cleanMarkers(computedPositionMarkerList);
            setComputedPositionMarkerList([]);
            return;
        }
        if (!computedRacePositionData || computedRacePositionData.length === 0 || !computedPositionMarkerList) {
            return;
        }
        let computedPositionMarkerListCopy = computedPositionMarkerList.slice();
        for (let c = 0; c < computedRacePositionData.length; c++) {
            let obj = computedRacePositionData[c];
            if (!obj) continue;
            if (!obj.position || !obj.position.latitude || obj.position.latitude === 0) continue;

            try {
                let position = {lat: Number(obj.position.latitude), lng: Number(obj.position.longitude)};
                const marker = computedPositionMarkerListCopy.find(marker => marker.number === obj.number);
                if (marker) {
                    marker.marker.setPosition(position);
                    marker.marker.setVisible(showComputedPosition && obj.status === 'DP');
                } else {
                    let m = new window.google.maps.Marker({
                        map: map,
                        position: position,
                        visible: showComputedPosition,
                        icon: process.env.PUBLIC_URL + `/images/horse_num/${obj.number}.png`,
                        zindex: 1
                    })
                    computedPositionMarkerListCopy.push({number: obj.number, marker: m});
                }
            } catch (error) {
                console.log(error);
            }

            // map follow
            if (raceTrack && followRace) {
                adjustCenterAndBound(computedPositionMarkerListCopy, false);
            }
        }
        setComputedPositionMarkerList(computedPositionMarkerListCopy);
    };

    const setRawPositionData = (racePositionData) => {
        if (!window.google || !map) {
            return;
        }
        if (!racePositionData || racePositionData.length === 0 || !rawPositionMarkerList) {
            return;
        }
        let rawPositionMarkerListCopy = rawPositionMarkerList.slice();
        for (let c = 0; c < racePositionData.length; c++) {
            let obj = racePositionData[c];
            if (!obj) continue;
            if (!obj.position || !obj.position.latitude || obj.position.latitude === 0) continue;

            try {
                let position = {lat: Number(obj.position.latitude), lng: Number(obj.position.longitude)};
                const marker = rawPositionMarkerListCopy.find(marker => marker.number === obj.number);
                if (marker) {
                    marker.marker.setPosition(position);
                    marker.marker.setVisible(showRawPosition);
                } else {
                    let m = new window.google.maps.Marker({
                        map: map,
                        position: position,
                        visible: showRawPosition,
                        icon: process.env.PUBLIC_URL + `/images/horse_num_raw_hpv2/${obj.number}.png`,
                        zindex: 1
                    })
                    rawPositionMarkerListCopy.push({number: obj.number, marker: m});
                }
            } catch (error) {
                console.log(error);
            }

        }
        setRawPositionMarkerList(rawPositionMarkerListCopy);
    };

    // getTrack ----------------------------------------------------
    // display track on map
    const setTrack = (trackContent) => {
        if (trackContent) {
            let innerArrayMark = [];
            let outerArrayMark = [];
            let pipelineList =[];
            const lines = trackContent.split(/\r?\n/);
            // remove empty line at the end
            if (lines[lines.length - 1].length === 0) {
                lines.pop();
            }
            for (let p = 1; p < lines.length; p++) {
                const values = lines[p].split(';');
                let posM = {
                    lat: parseFloat(values[1]),
                    lng: parseFloat(values[2]),
                };

                let posM_ext = {
                    lat: parseFloat(values[5]),
                    lng: parseFloat(values[6]),
                };

                if (values[7] && ((values[7].trim() === 'PCDEP' || values[7].trim() === 'PCARR') ||
                    (Number(values[7]) !== 0 && parseInt(values[7].substr(2))))) {
                    innerArrayMark.push(
                        <Marker
                            key={`marker_${p}`}
                            visible={showTrackGate}
                            position={posM}
                            icon={{
                                url: `${process.env.PUBLIC_URL}/images/icons/green_dot.png`,
                                scaledSize: new window.google.maps.Size(icon_size, icon_size)
                            }}>
                            <p>{values[7]}</p>
                            {showTrackGate?
                            <InfoWindow
                                key={`info_${p}`}
                                position={posM}
                                clickable={true}>
                                <p>{values[7]}</p>
                            </InfoWindow>:''
                            }
                        </Marker>
                    );
                    pipelineList.push(<Polyline key={`polyline_${p}`} path={[posM, posM_ext]}/>);
                    outerArrayMark.push(
                        <Marker
                            key={`marker_ext_${p}`}
                            visible={showOuterTrack}
                            position={posM_ext}
                            icon={{
                                url: `${process.env.PUBLIC_URL}/images/icons/green_dot.png`,
                                scaledSize: new window.google.maps.Size(icon_size, icon_size)
                            }}
                        />
                    );
                } else {
                    innerArrayMark.push(
                        <Marker
                            key={`marker_${p}`}
                            visible={showInnerTrack}
                            position={posM}
                            icon={{
                                url: `${process.env.PUBLIC_URL}/images/icons/blue_dot.png`,
                                scaledSize: new window.google.maps.Size(icon_size, icon_size)
                            }}/>
                    );

                    outerArrayMark.push(
                        <Marker
                            key={`marker_ext_${p}`}
                            visible={showOuterTrack}
                            position={posM_ext}
                            icon={{
                                url: `${process.env.PUBLIC_URL}/images/icons/red_dot.png`,
                                scaledSize: new window.google.maps.Size(icon_size, icon_size)
                            }}
                        />
                    );
                }
            }

            setInnerTrackMarkerList(innerArrayMark);
            setOuterTrackMarkerList(outerArrayMark);
            setPipelineList(pipelineList);
            adjustCenterAndBound(innerArrayMark, true);
            map.setZoom(17);
        }
    };

    const adjustCenterAndBound = (data, useProps) => {
        if (raceTrack && followRace) {
            let bounds = new window.google.maps.LatLngBounds();
            for (let i = 0; i < data.length; i++) {
                if (useProps) {
                    bounds.extend(data[i].props.position)
                } else {
                    if (data[i].marker.visible) {
                        bounds.extend(data[i].marker.position)
                    }
                }
            }
            if (map) {
                map.setCenter(bounds.getCenter());
                if (fitBounds) {
                    map.fitBounds(bounds);
                }
            }
        }
    }

    const outerTrackHandler = React.useCallback(function callback(value) {
        setShowOuterTrack(value);
    }, [])

    const innerTrackHandler = React.useCallback(function callback(value) {
        setShowInnerTrack(value);
    }, [])

    const trackGateHandler = React.useCallback(function callback(value) {
        setShowTrackGate(value);
    }, [])

    const rawPositionHandler = React.useCallback(function callback(value) {
        setShowRawPosition(value);
    }, [])

    const computedPositionHandler = React.useCallback(function callback(value) {
        setShowComputedPosition(value);
    }, [])

    const followRaceHandler = React.useCallback(function callback(value) {
        setFollowRace(value);
    }, [])

    const fitBoundsHandler = React.useCallback(function callback(value) {
        setFitBounds(value);
    }, [])

    const mapTypeHandler = React.useCallback(function callback(value) {
        if (value) {// true is equals to satellite
            setMapType('SATELLITE');
        } else {
            setMapType('ROADMAP');
        }
    }, [])

    // useEffect ------------------------------------------------------
    useEffect(() => {
        if (loading) {
            if (window.google) {
                setToolbarButton(getToolbarButton());
                setMapOptions(default_map_options);
                setLoading(false);
                setMapCenter(JSON.parse(localStorage.getItem('mapDefaultCenter')));
                setMapZoom(Number(localStorage.getItem('mapDefaultZoom')));
            }
        }
        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.google]);

    useEffect(() => {
        if (map && raceTrack) {
            setTrack(raceTrack);
        } else {
            clearMap();
            setInnerTrackMarkerList([]);
            setOuterTrackMarkerList([]);
            setPipelineList([]);
            setComputedPositionMarkerList([]);
            setRawPositionMarkerList([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, raceTrack, showTrackGate, showOuterTrack, showInnerTrack]);

    useEffect(() => {
        if (positionRaceData.raw_position) {
            setRawPositionData(positionRaceData.raw_position);
        }
        if (positionRaceData.computed_position) {
            setComputedPositionData(positionRaceData.computed_position);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [positionRaceData]);

    useEffect(() => {
        if (map) {
            if (mapType === 'ROADMAP') {
                map.setMapTypeId(window.google.maps.MapTypeId.ROADMAP);
            } else {
                map.setMapTypeId(window.google.maps.MapTypeId.SATELLITE);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapType]);

    const mapComponent = useMemo(() => {
        return <LoadScript
                   googleMapsApiKey="AIzaSyBRHVJET38Cx1CGYAkiErRdKh7gi2V4etM"
                   loadingElement={<Loader/>}>
                   {loading ?
                   <Loader/> :
                   <GoogleMap
                        id='pilotageMap'
                        mapContainerStyle={containerStyle}
                        zoom={mapZoom}
                        center={mapCenter}
                        onLoad={onMapLoad}
                        options={mapOptions}>

                            {props.children}
                            {innerTrackMarkerList}
                            {outerTrackMarkerList}
                            {pipelineList}
                            {toolbarButton}
                            <Drawer title={t('Map Control Options')} onClose={onClose} visible={visible}
                                    placement='bottom' height={'25%'}
                                    id={'map_control'}>
                                <div id={'map_control'}>
                                    <div className={('item')}>
                                        <label htmlFor={'map_raw_position_switch'}>{t('Display raw race positions')}</label>
                                        <Switch
                                            id="map_raw_position_switch"
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                            checked={showRawPosition}
                                            onChange={(v) => { rawPositionHandler(v); }}>
                                        </Switch>
                                    </div>
                                    <div className={('item')}>
                                        <label htmlFor={'map_computed_position_switch'}>{t('Display computed race positions')}</label>
                                        <Switch
                                            id="map_computed_position_switch"
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                            checked={showComputedPosition}
                                            onChange={(v) => { computedPositionHandler(v); }}>
                                        </Switch>
                                    </div>
                                    <div className={('item')}>
                                        <label htmlFor={'map_track_gates_switch'}>{t('Display intermediate gates')}</label>
                                        <Switch
                                            id="map_track_gates_switch"
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                            checked={showTrackGate}
                                            onChange={(v) => { trackGateHandler(v); }}>
                                        </Switch>
                                    </div>
                                    <div className={('item')}>
                                        <label htmlFor={'map_inner_track_switch'}>{t('Display inner track')}</label>
                                        <Switch
                                            id='map_inner_track_switch'
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                            checked={showInnerTrack}
                                            onChange={(v) => { innerTrackHandler(v); }}>
                                        </Switch>
                                    </div>
                                    <div className={('item')}>
                                        <label htmlFor={'map_inner_track_switch'}>{t('Display outer track')}</label>
                                        <Switch
                                            id='map_inner_track_switch'
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                            checked={showOuterTrack}
                                            onChange={(v) => { outerTrackHandler(v); }}>
                                        </Switch>
                                    </div>
                                    <div className={('item')}>
                                        <label htmlFor={'fit_bounds_switch'}>{t('Fit bounds when following race on track')}</label>
                                        <Switch
                                            id="fit_bounds_switch"
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                            checked={fitBounds}
                                            onChange={(v) => { fitBoundsHandler(v);}}>
                                        </Switch>
                                    </div>
                                    <div className={('item')}>
                                        <label htmlFor={'follow_race_on_track_switch'}>{t('Follow race on track')}</label>
                                        <Switch
                                            id='follow_race_on_track_switch'
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                            checked={followRace}
                                            onChange={(v) => { followRaceHandler(v);}}>
                                        </Switch>
                                    </div>
                                    <div className={('item')}>
                                        <label htmlFor={'map_type_switch'}>{t('Map type')}</label>
                                        <Switch
                                            id='map_type_switch'
                                            checkedChildren={'Satellite'}
                                            unCheckedChildren={'Roadmap'}
                                            checked={mapType === 'SATELLITE'}
                                            onChange={(v) => { mapTypeHandler(v);}}>
                                        </Switch>
                                    </div>
                                </div>
                            </Drawer>
                    </GoogleMap>}
                </LoadScript>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, innerTrackMarkerList, outerTrackMarkerList, positionRaceData, visible])

    // render ---------------------------------------------------------
    return <> {mapComponent} </>
})

export default MonitoringMap;