import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Button, Checkbox, Form, Input, message, Select, Tabs} from 'antd';
import settings from "../services/settings.service";
import positionServer from "../services/position-server.service";

const {TabPane} = Tabs;
const {Option} = Select;
const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};
const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};

export default function PrivateSettings() {
    const {t} = useTranslation();
    const [formHpv2Selection] = Form.useForm();
    const [formHpv2Settings] = Form.useForm();
    const [formMapSettings] = Form.useForm();
    const [hpv2List, setHpv2List] = useState([]);
    const [mccMncList, setMccMncList] = useState([]);
    const [user, setUser] = useState({});
    const [hpv2Selected, setHpv2Selected] = useState({});

    // loadHpv2 ------------------------------------------------------
    const loadHpv2 = async () => {
        positionServer.getAll().then(positionsServers => {
            setHpv2List(positionsServers.data);
            const currentSelectedServer = positionsServers.data.find(server => server.isSelected === true);
            if (currentSelectedServer) {
                updateHpv2Fields(currentSelectedServer);
                setHpv2Selected(currentSelectedServer);
            }
        });
    }

    const loadHpv2Settings = async () => {
        positionServer.getHpv2Settings().then(hpv2Settings => {
            updateHpv2SettingsFields(hpv2Settings.data);
        });
    }

    const loadMccMncList = () => {
        positionServer.getAllMccMnc().then(mccMncList => {
            setMccMncList(mccMncList.data);
        })
    }

    const hpv2ListHandler = (uuid) => {
        if (uuid) {
            const selectedServer = hpv2List.find(server => server.uuid === uuid);
            if (selectedServer) {
                updateHpv2Fields(selectedServer);
            }
        }
    }

    const updateHpv2Fields = (selectedServer) => {
        formHpv2Selection.setFieldsValue({hpv2_uuid: `${selectedServer.uuid}`});
        formHpv2Selection.setFieldsValue({is_hpv2_control_enabled: selectedServer.isHpv2ControlEnabled});
    }

    const updateHpv2SettingsFields = (hpv2Settings) => {
        formHpv2Settings.setFieldsValue({hpv2_mode: `${hpv2Settings.mode}`});
        formHpv2Settings.setFieldsValue({hpv2_tracker_height: hpv2Settings.height_sensor});
        formHpv2Settings.setFieldsValue({hpv2_channel: hpv2Settings.channel});
        formHpv2Settings.setFieldsValue({hpv2_mcc_mnc: `${hpv2Settings.mccMnc}`});
        formHpv2Settings.setFieldsValue({hpv2_network_technology: `${hpv2Settings.networkTechnology}`});
    }

    const hpv2GetLabel = (item) => {
        return item.name+' ('+item.url+')';
    }

    // onFinish -------------------------------------------------------
    const onFinishHpv2Selection = (values) => {
        const positionServerUpdate = {
            uuid: values.hpv2_uuid,
            isHpv2ControlEnabled: values.is_hpv2_control_enabled,
            isSelected: true,
        };
        positionServer
            .update(positionServerUpdate)
            .then(res => {
                if (res.status === 200 || res.status === 204) {
                    message.success(t('common.save_success'));
                    window.location.reload();
                } else {
                    message.error(t('common.error'));
                }
            })
            .catch(() => {
                message.error(t('common.error'));
            })
    }

    const onFinishHpv2Settings = (values) => {
        const settingsServerUpdate ={
            mode: Number(values.hpv2_mode),
            height_sensor: Number(values.hpv2_tracker_height),
            channel: Number(values.hpv2_channel),
            mccMnc: Number(values.hpv2_mcc_mnc),
            networkTechnology: Number(values.hpv2_network_technology)};
        positionServer
            .updateHpv2Settings(settingsServerUpdate)
            .then(res => {
                if (res.status === 200 || res.status === 204) {
                    message.success(t('common.save_success'));
                } else {
                    message.error(t('common.error'));
                }
            })
            .catch(() => {
                message.error(t('common.error'));
            })
    }

    const onFinishMapSettings = (values) => {
        const settingsUpdate ={
            map_default_center: values.map_default_center,
            debug_mode: values.debug_mode,
            map_default_zoom: values.map_default_zoom}
        settings
            .save(settingsUpdate)
            .then(async record => {
                let settings = JSON.parse(record.config.data);
                localStorage.setItem("mapDefaultCenter", settings.map_default_center);
                localStorage.setItem("mapDefaultZoom", settings.map_default_zoom);
                message.success(t('common.save_success'));
            })
            .catch(() => {
                message.error(t('common.error'));
            })
    }

    // load_data ------------------------------------------------------
    const loadData = async () => {
        settings.get().then(settings => {
            localStorage.setItem("mapDefaultCenter", settings.data[0].map_default_center);
            localStorage.setItem("mapDefaultZoom", settings.data[0].map_default_zoom);
            formMapSettings.setFieldsValue({map_default_center: settings.data[0].map_default_center});
            formMapSettings.setFieldsValue({map_default_zoom: settings.data[0].map_default_zoom});
            formMapSettings.setFieldsValue({debug_mode: settings.data[0].debug_mode});
        });
    }

    // useEffect ------------------------------------------------------
    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('user')));
        loadHpv2().then();
        loadMccMncList();
        loadHpv2Settings().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (hpv2List) {
            loadData().then();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hpv2List])

    // render ---------------------------------------------------------
    return (
        <div className="private_page_wrapper">
            <div className="header">
                <span className="title">{t('Menu.Settings')}</span>
            </div>
            <Tabs defaultActiveKey="2">
                {user.roles && user.roles.includes("ROLE_ADMIN") && <>
                <TabPane tab={'HPV2 '+t('settings.title')} key="1">
                    <Form
                        {...layout}
                        form={formHpv2Selection}
                        name="hpv2_selection_form"
                        className="common_form"
                        onFinish={onFinishHpv2Selection}>

                        <Form.Item name="hpv2_uuid" label={t('settings.hpv2_addr')} rules={[{required: true}]}>
                            <Select onChange={hpv2ListHandler}>
                                {hpv2List.map(hpv2 => {
                                    return <Option key={hpv2.uuid} value={hpv2.uuid}>
                                        {hpv2GetLabel(hpv2)}
                                    </Option>
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item name="is_hpv2_control_enabled" label={t('settings.hpv2_control_enabled')} valuePropName="checked">
                            <Checkbox/>
                        </Form.Item>

                        <Form.Item {...tailLayout} className="form_toolbar">
                            <Button type="primary" htmlType="submit">
                                {t('common.save')}
                            </Button>
                        </Form.Item>

                    </Form>
                </TabPane>
                </>
                }
                <TabPane tab={'HPV2 '+hpv2Selected.name + ' ' +t('settings.hpv2_settings_title')} key="2">
                    <Form
                        {...layout}
                        form={formHpv2Settings}
                        name="hpv2_settings_form"
                        className="common_form"
                        onFinish={onFinishHpv2Settings}>
                        <fieldset>
                            <legend>{t('settings.general')}</legend>
                            <Form.Item name="hpv2_mode" label={t('settings.hpv2_mode')} rules={[{required: true}]}>
                                <Select value="hpv2_mode">
                                    <Option key="outdoor" value="1">
                                        Outdoor
                                    </Option>
                                    <Option key="indoor" value="2">
                                        Indoor
                                    </Option>
                                    <Option key="outdoor-fast" value="3">
                                        Outdoor-Fast
                                    </Option>
                                    <Option key="outdoor-sync-gps" value="4">
                                        Outdoor-SyncGPS
                                    </Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="hpv2_tracker_height" label={t('settings.hpv2_tracker_height')} rules={[{required: true}]}>
                                <Input placeholder="" type="number"/>
                            </Form.Item>
                            <Form.Item name="hpv2_channel" label={t('settings.hpv2_channel')} rules={[{required: true}]}>
                                <Select value="hpv2_channel">
                                    <Option key="hpv2_channel_1" value="1">
                                        1
                                    </Option>
                                    <Option key="hpv2_channel_2" value="2">
                                        2
                                    </Option>
                                    <Option key="hpv2_channel_3" value="3">
                                        3
                                    </Option>
                                    <Option key="hpv2_channel_5" value="5">
                                        5
                                    </Option>
                                </Select>
                            </Form.Item>
                        </fieldset>
                        <fieldset>
                            <legend>{t('settings.network')}</legend>
                            <Form.Item name="hpv2_mcc_mnc"
                                       label={t('settings.hpv2_mcc_mnc')}
                                       rules={[{required: true}]}
                                       extra="Orange : 20801 / SFR : 20810 / Bouygues Tel : 20820 / Free : 20815">
                                <Select value="hpv2_mcc_mnc">
                                    {mccMncList.map(mccMnc => {
                                        return <Option key={`${mccMnc.mcc}${mccMnc.mnc}`} value={`${mccMnc.mcc}${mccMnc.mnc}`}>
                                            {mccMnc.countryCode + ' ' + mccMnc.brand + ' '+ mccMnc.mcc+mccMnc.mnc}
                                        </Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item name="hpv2_network_technology" label={t('settings.hpv2_network_technology')} rules={[{required: true}]}>
                                <Select value="hpv2_network_technology">
                                    <Option key="hpv2_network_technology_3" value="3">
                                        3G
                                    </Option>
                                    <Option key="hpv2_network_technology_4" value="4">
                                        4G
                                    </Option>
                                </Select>
                            </Form.Item>
                        </fieldset>
                        <Form.Item {...tailLayout} className="form_toolbar">
                            <Button type="primary" htmlType="submit">
                                {t('common.save')}
                            </Button>
                        </Form.Item>
                    </Form>
                </TabPane>
                <TabPane tab={'Map '+t('settings.map_settings_title')} key="3">
                    <Form
                        {...layout}
                        form={formMapSettings}
                        name="map_settings_form"
                        className="common_form"
                        onFinish={onFinishMapSettings}>
                        <Form.Item name="map_default_center" label={t('settings.map_default_center')} rules={[{required: true}]}>
                            <Input placeholder={`{"lat":46.80372236013024,"lng":2.6458026132760217}`}/>
                        </Form.Item>

                        <Form.Item name="map_default_zoom" label={t('settings.map_default_zoom')} rules={[{required: true}]}>
                            <Input placeholder="6"/>
                        </Form.Item>
                        <Form.Item {...tailLayout} className="form_toolbar">
                            <Button type="primary" htmlType="submit">
                                {t('common.save')}
                            </Button>
                        </Form.Item>
                    </Form>
                </TabPane>
            </Tabs>
        </div>
    )

}
